import styled from '@emotion/styled';
import SectionTitle from '../SectionTitle';
import Container from './Container';
import { breakpointQueries, breakpoints, colors, spacing } from '../../../utils/styleguide';
import Typography from '../text/Typography';
import PrimaryButton from '../buttons/PrimaryButton';
import Divider from '../Divider';
import { useMediaQuery } from '../../../utils/hooks/useMediaQuery';
import { css } from '@emotion/react';
import HubSpotForm, { FormFields } from '../HubSpotForm';
import { Document } from '@contentful/rich-text-types';
import RichText from '../../contentful-elements/RichText/RichText';
import { hasValue } from '../../../utils/typeCheck';
import {
  TypeCategorizedRichTextSkeleton,
  isTypeCategorizedRichText,
} from '../../../../@types/generated/TypeCategorizedRichText';
import { TypeFormSkeleton, isTypeForm } from '../../../../@types/generated/TypeForm';
import { Entry } from 'contentful';
import { getLinkHref } from '../../contentful-elements/util/linkHref';

export function toFormCTASection(
  sections?: Array<
    | Entry<
        TypeCategorizedRichTextSkeleton | TypeFormSkeleton,
        'WITHOUT_UNRESOLVABLE_LINKS',
        string
      >
    | undefined
  >,
) {
  return sections
    ?.map((section) => {
      if (!section) {
        return null;
      }
      if (isTypeCategorizedRichText(section)) {
        return {
          title: section?.fields.title,
          description: section?.fields.description,
          link: {
            title: section?.fields.link?.[0]?.fields.identifier,
            url: getLinkHref(section?.fields.link?.[0]),
          },
        };
      } else if (isTypeForm(section)) {
        return {
          title: section.fields.formTitle,
          description: section.fields.formDescription,
          identifier: section.fields.identifier,
          submitUrl: section.fields.url,
          submitText: section.fields.submitText,
          formType: section.fields.type,
          successMessage: section.fields.successMessage,
          errorMessage: section.fields.errorMessage,
          fields: section.fields.formItems?.filter(hasValue).map((field) => ({
            fieldName: field.fields.fieldName,
            type: field.fields.type,
            placeholder: field.fields.placeholder,
            required: field.fields.required,
          })),
        };
      } else {
        return null;
      }
    })
    .filter(hasValue);
}

const ContentWrapper = styled.div`
  padding-left: 0;

  ${breakpoints.desktop} {
    padding-left: ${spacing[5]}px;
  }
`;

const Content = styled.div`
  ${breakpoints.desktop} {
    display: flex;
    flex-direction: row;
  }
`;

const ContentSection = styled.div`
  flex: 1;
`;

const Title = styled(Typography)`
  margin-top: ${spacing[6]}px;
  margin-bottom: ${spacing[5]}px;
`;

const CTA = styled(PrimaryButton)`
  margin-top: ${spacing[4]}px;
`;

const StyledDivider = styled(Divider)`
  margin: ${spacing[4]}px 0;
  ${breakpoints.desktop} {
    margin: 0 ${spacing[6]}px;
  }
`;

type CTAText = {
  title?: string;
  renderDescription?: () => JSX.Element;
  description?: Document;
  link?: {
    title?: string;
    url?: string;
  };
};

export type CTAForm = {
  identifier: string;
  title?: string;
  submitUrl?: string;
  fields?: FormFields[];
  submitText?: string;
  formType?: string;
  successMessage?: string;
  errorMessage?: string;
};

function isCTAForm(section: unknown): section is CTAForm {
  return (section as CTAForm).identifier !== undefined;
}

function TextSection({
  title,
  renderDescription,
  description,
  link,
  useSecondaryButtonStyle,
}: CTAText & { useSecondaryButtonStyle?: boolean }) {
  const Description = renderDescription
    ? renderDescription
    : () => <RichText document={description} />;
  return (
    <>
      <Typography variant="overline" color={colors.blackSecondary}>
        {title}
      </Typography>
      {Description && (
        <div
          css={css`
            margin-top: ${spacing[3]}px;
          `}
        >
          <Description />
        </div>
      )}
      {useSecondaryButtonStyle ? (
        <CTA size="small" variant="outline" label={link?.title} href={link?.url} />
      ) : (
        <CTA size="small" trailingIconName="chevron-right" label={link?.title} href={link?.url} />
      )}
    </>
  );
}

export default function FormCTA({
  ctaFormSectionTitle,
  ctaFormTitle,
  ctaFormSections,
  backgroundColor,
  id,
}: {
  ctaFormSectionTitle?: string;
  ctaFormTitle?: string;
  ctaFormSections?: Array<CTAText | CTAForm>;
  backgroundColor?: string;
  id?: string;
}) {
  const [isDesktop] = useMediaQuery(breakpointQueries.desktop);

  if (!ctaFormSections?.length) return null;

  return (
    <Container id={id} backgroundColor={backgroundColor}>
      <SectionTitle title={ctaFormSectionTitle ?? ''} />
      <ContentWrapper>
        <Title variant="h3Bold">{ctaFormTitle}</Title>
        <Content>
          <ContentSection>
            {isCTAForm(ctaFormSections[0]) ? (
              <HubSpotForm
                {...ctaFormSections[0]}
                isAvailable={!!ctaFormSections[0]}
                titleVariant="overline"
                buttonSize="small"
              />
            ) : (
              <TextSection {...ctaFormSections[0]} />
            )}
          </ContentSection>
          <StyledDivider vertical={isDesktop} />
          <ContentSection>
            {isCTAForm(ctaFormSections[1]) ? (
              <HubSpotForm
                {...ctaFormSections[1]}
                isAvailable={!!ctaFormSections[1]}
                titleVariant="overline"
                buttonSize="small"
                buttonVariant="outline"
              />
            ) : (
              <TextSection {...ctaFormSections[1]} useSecondaryButtonStyle />
            )}
          </ContentSection>
        </Content>
      </ContentWrapper>
    </Container>
  );
}
